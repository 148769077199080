const useForm = {
  // @TODO: handle other types
  registerInput: ({ machine }) => {
    return (name) => {
      return {
        blur: () => {
          machine.send('FIELD.BLUR', { name })
        },
        input: (event) => {
          const value = event.target ? event.target.value : event

          machine.send('FIELD.CHANGE', { name, data: value })
        },
        focus: () => {
          machine.send('FIELD.FOCUS', { name })
        },
      }
    }
  },
  registerSelect: ({ machine }) => {
    return (name) => {
      return {
        change: (event) => {
          const value = event.target ? event.target.value : event

          machine.send('FIELD.CHANGE', { name, data: value })
        },
      }
    }
  },
  hasError: ({ context }) => {
    return (name) => {
      return Boolean(context.errors[name] && context.touched[name])
    }
  },
  isValid: ({ context }) => {
    const errors = Object.values(context.errors)

    return Boolean(!errors.length) && errors.every((err) => !err)
  },
}

export default useForm
