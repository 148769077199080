import { Machine, assign, sendParent } from 'xstate'
import { choose } from 'xstate/lib/actions'

import useForm from '../useForm'

export default Machine({
  id: 'input',
  initial: 'idle',
  context: {
    initialValue: '',
    name: '',
    value: '',
    touched: false,
  },
  states: {
    idle: {
      on: {
        CHANGE: {
          actions: [
            assign({
              value: (_, event) => event.data,
            }),
            sendParent((context) => ({
              type: 'FIELD.COMMIT',
              field: context,
            })),
            choose([
              {
                cond: (context) => useForm.hasError(context),
                actions: sendParent('VALIDATE_FIELDS'),
              },
            ]),
          ],
        },
        BLUR: {
          actions: [
            assign({ touched: true }),
            sendParent((context) => ({ type: 'FIELD.COMMIT', field: context })),
            sendParent('VALIDATE_FIELDS'),
          ],
        },
      },
    },
  },
})
