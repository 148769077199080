import { useStore } from '@vueblocks/vue-use-vuex'
import { Ref, computed, ref, watch } from 'vue-demi'

import { getSubscriptionSimulation } from '@front/common/endpoints'
import { Customer, Item, Service, Subscription } from '@front/common/types'
import {
  createUpsellService,
  deleteItem,
  findItem,
  getActiveServices,
  getFlattenItems,
  getItemById,
  getItemsByModificationType,
  getServiceById,
  isArrayEmpty,
  isObjectEmpty,
  upsertItem,
  upsertService,
} from '@front/common/utils'

interface Iprops {
  subscription?: Ref<Subscription>
  customer?: Ref<Customer>
  refreshOnInit?: boolean
  disableRefresh?: boolean
  persist?: boolean
  initialItems?: Item[]
}

export default function useCart({
  subscription,
  customer,
  refreshOnInit = false,
  disableRefresh = false,
  persist = false,
  initialItems = [],
}: Iprops) {
  const store = useStore()
  const localSubscription = ref<Subscription>()
  const isCartLoading = ref(false)
  const localIsInitialized = ref(false)

  const cartSubscription = computed<Subscription>(() => {
    return persist
      ? store.getters['cart/getSubscription']
      : localSubscription.value
  })

  const cart = computed(() => {
    return getActiveServices(cartSubscription.value?.services || [])
  })

  const cartWithInactives = computed(() => {
    return cartSubscription.value?.services || []
  })

  const flattenCart = computed(() => {
    return getFlattenItems(cart.value)
  })

  const additionsItemsCount = computed(() => {
    const { additions, updates } = getItemsByModificationType(
      getFlattenItems(subscription.value.services),
      getFlattenItems(cartWithInactives.value),
    )

    return [...updates, ...additions].reduce((acc, item) => {
      const original = findItem(
        item,
        getFlattenItems(subscription.value.services),
      )

      if (!original) {
        return acc + item.quantity.base
      }

      return acc + Math.max(item.quantity.base - original.quantity.base, 0)
    }, 0)
  })

  function setIsCartLoading(value: boolean) {
    isCartLoading.value = value
  }

  function setIsInitialized(value: boolean) {
    if (persist) {
      store.dispatch('cart/setIsInitialized', true)
    }

    localIsInitialized.value = value
  }

  function insertSubscription(subscription: Subscription) {
    if (persist) {
      store.dispatch('cart/upsert', subscription)
      return
    }

    localSubscription.value = subscription
  }

  async function refreshCart(newCart: Service[]) {
    if (disableRefresh) {
      insertSubscription({
        ...cartSubscription.value,
        services: newCart,
      })
      return
    }

    setIsCartLoading(true)

    const newSubscription = await getSubscriptionSimulation({
      ...cartSubscription.value,
      discount: customer?.value?.discount,
      services: newCart,
    })

    setIsCartLoading(false)

    insertSubscription(newSubscription)
  }

  async function updateServiceToCart(service: Service) {
    return refreshCart(upsertService(service, cartWithInactives.value))
  }

  async function upsertItemToCart(item: Item) {
    const itemService = getServiceById(cart.value, item.serviceId)

    if (itemService) {
      const newService = {
        ...itemService,
        items: upsertItem(item, itemService.items),
      }

      return updateServiceToCart(newService)
    }

    return updateServiceToCart(
      createUpsellService(
        [item],
        item.serviceId,
        subscription?.value?.id,
        item.petId,
      ),
    )
  }

  async function deleteItemFromCart(itemId: Item['id']) {
    const { serviceId } = getItemById(flattenCart.value, itemId)

    const itemService = getServiceById(cart.value, serviceId)

    const newService = {
      ...itemService,
      items: deleteItem(itemId, itemService.items),
    }

    return updateServiceToCart(newService)
  }

  async function insertInitialItems() {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of initialItems) {
      // eslint-disable-next-line no-await-in-loop
      await upsertItemToCart(item)
    }
  }

  watch(
    subscription,
    (newSubscription) => {
      if (isArrayEmpty(newSubscription?.services)) {
        insertInitialItems()
        return
      }

      // on est a l'init
      if (!localIsInitialized.value) {
        // si on est pas en mode persist ou si on est en mode persist mais que `cartSubscription` est vide
        if (
          !persist ||
          (isObjectEmpty(cartSubscription.value) &&
            !store.getters['cart/isInitialized'])
        ) {
          insertInitialItems()
          insertSubscription(newSubscription)
          setIsInitialized(true)

          if (refreshOnInit) {
            refreshCart(newSubscription.services)
          }

          return
        }
        return
      }

      // si on est pas a l'init
      insertSubscription(newSubscription)
    },
    { immediate: true, deep: true },
  )

  return {
    cart,
    cartWithInactives,
    flattenCart,
    upsertItemToCart,
    deleteItemFromCart,
    setIsCartLoading,
    isCartLoading,
    cartSubscription,
    updateServiceToCart,
    insertSubscription,
    additionsItemsCount,
  }
}
