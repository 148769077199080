import { Machine, assign, send } from 'xstate'
import { choose } from 'xstate/lib/actions'

import { isObjectEmpty } from '@front/common/utils'

import { subMachines } from '../constants'
import events from '../events'
import { createSubMachines, makeEveryFieldsTouched } from '../utils'

function validate({ schema, values, i18n }) {
  if (!schema) {
    return {}
  }

  try {
    schema.validateSync(values, { abortEarly: false })

    return {}
  } catch ({ path, message, inner }) {
    return inner?.length === 0
      ? { path, message }
      : inner.reduce((acc, error) => {
          const translation = i18n(
            error.message.key || error.message,
            error.message.values,
          )

          return {
            ...acc,
            [error.path]: translation,
          }
        }, {})
  }
}

export default Machine({
  initial: 'mounting',
  states: {
    mounting: {
      entry: assign({
        fields: ({ schema, ...context }) =>
          createSubMachines(schema, context, subMachines),
        values: ({ initialValues = {} }) => initialValues,
      }),
      on: {
        MOUNT: {
          target: 'mounted',
          actions: assign({
            i18n: (context, { i18n }) => i18n,
          }),
        },
      },
    },
    mounted: {
      on: { ...events },
    },
    validating: {
      entry: send((context) => {
        const errors = validate(context)

        return isObjectEmpty(errors)
          ? 'VALIDATION_SUCCESS'
          : { type: 'VALIDATION_FAILURE', errors }
      }),
      on: {
        VALIDATION_SUCCESS: {
          target: 'mounted',
          actions: [
            assign({
              touched: {},
              isSubmiting: false,
              errors: {},
            }),
            choose([
              {
                cond: ({ isSubmiting }) => isSubmiting,
                actions: [
                  assign({
                    touched: {},
                    isSubmiting: false,
                  }),
                ],
              },
            ]),
          ],
        },
        VALIDATION_FAILURE: {
          target: 'mounted',
          actions: [
            assign({
              errors: (context, { errors }) => {
                return errors
              },
            }),
            choose([
              {
                cond: ({ isSubmiting }) => isSubmiting,
                actions: [
                  assign({
                    touched: makeEveryFieldsTouched,
                    isSubmiting: false,
                  }),
                ],
              },
            ]),
          ],
        },
      },
    },
  },
})
