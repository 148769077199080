var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapperRef",staticClass:"ce-ingredients--wrapper"},[_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h2,"tag":"h2"}},[_vm._v("\n    Des ingrédients connus pour leurs bienfaits\n  ")]),_c('div',{staticClass:"ce-ingredients-image--wrapper"},[_c('TwicImg',{staticClass:"ce-ingredients--image",attrs:{"ratio":_vm.isMobile ? '1' : '16:9',"alt":"Tous les ingrédients d'une recette Japhy présentés sur une table.","src":"https://japhy-assets.twic.pics/download?id=13rS8RGneT5-I3oDWSxq3teDRRbhsdPJx"}}),_vm._l((_vm.ingredients),function(ref){
var description = ref.description;
var id = ref.id;
var title = ref.title;
var imageUrl = ref.imageUrl;
return _c('BoundingBox',{key:id,staticClass:"ce-ingredients--ingredient",style:({
        left: ((_vm.getPosition(id)[0]) + "%"),
        top: ((_vm.getPosition(id)[1]) + "%"),
      }),attrs:{"should-show-content":_vm.openedIngredientId === id},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('Button',{staticClass:"ce-ingredients-ingredient--button",attrs:{"appearance":"clean"},on:{"mouseenter":function($event){return _vm.selectIngredient(id)},"mouseleave":function($event){return _vm.selectIngredient()},"click":function($event){return _vm.selectIngredient(id)}}},[_c('div',{staticClass:"ce-ingredients--pellet"})])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"ce-ingredients-ingredient--box"},[_c('TwicImg',{staticClass:"ce-ingredients-ingredient-image",attrs:{"ratio":"none","focus":"auto","alt":title,"src":imageUrl}}),_c('div',{staticClass:"ce-ingredients-ingredient-text"},[_c('Typography',[_vm._v("\n              "+_vm._s(title)+"\n            ")]),_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodySmallRegular}},[_vm._v("\n              "+_vm._s(description)+"\n            ")])],1)],1)]},proxy:true}],null,true)})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }