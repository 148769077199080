var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ce-howitworks--wrapper"},[_c('div',{staticClass:"ce-howitworks--container"},[_c('Typography',{staticClass:"ce-howitworks--title",attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h2,"tag":"h2"}},[_vm._v("\n      Comment ça marche concrètement ?\n    ")]),_c('div',{staticClass:"ce-howitworks--content"},[_c('div',{staticClass:"ce-howitworks--claims"},[_c('div',{class:[
            'ce-howitworks--claim',
            { isActive: _vm.isClaimActive(_vm.PROFILE_ID) } ],on:{"mouseenter":function($event){return _vm.selectClaim(_vm.PROFILE_ID)},"click":function($event){return _vm.selectClaim(_vm.PROFILE_ID)}}},[_c('img',{staticClass:"ce-howitworks-claim--image",attrs:{"width":"40","height":"40","src":require("@front/common/assets/number_one.svg"),"alt":"Numéro un"}}),_c('div',{staticClass:"ce-howitworks-claim-text--wrapper"},[_c('Typography',{staticClass:"ce-howitworks-claim--title",attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h4,"tag":"strong"}},[_vm._v("\n              Son profil\n            ")]),_c('Typography',{staticClass:"ce-howitworks-claim--description",attrs:{"tag":"p"}},[_vm._v("\n              Créez le profil de votre animal en quelques clics\n            ")])],1)]),_c('div',{class:[
            'ce-howitworks--claim',
            { isActive: _vm.isClaimActive(_vm.RECIPE_ID) } ],on:{"click":function($event){return _vm.selectClaim(_vm.RECIPE_ID)},"mouseenter":function($event){return _vm.selectClaim(_vm.RECIPE_ID)}}},[_c('img',{staticClass:"ce-howitworks-claim--image",attrs:{"width":"40","height":"40","src":require("@front/common/assets/number_two.svg"),"alt":"Numéro trois"}}),_c('div',{staticClass:"ce-howitworks-claim-text--wrapper"},[_c('Typography',{staticClass:"ce-howitworks-claim--title",attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h4,"tag":"strong"}},[_vm._v("\n              Sa recette\n            ")]),_c('Typography',{staticClass:"ce-howitworks-claim--description",attrs:{"tag":"p"}},[_vm._v("\n              Découvrez sa recette personnalisée selon ses besoins spécifiques\n            ")])],1)]),_c('div',{class:[
            'ce-howitworks--claim',
            { isActive: _vm.isClaimActive(_vm.TRIAL_OFFER_ID) } ],on:{"click":function($event){return _vm.selectClaim(_vm.TRIAL_OFFER_ID)},"mouseenter":function($event){return _vm.selectClaim(_vm.TRIAL_OFFER_ID)}}},[_c('img',{staticClass:"ce-howitworks-claim--image",attrs:{"width":"40","height":"40","src":require("@front/common/assets/number_three.svg"),"alt":"Numéro deux"}}),_c('div',{staticClass:"ce-howitworks-claim-text--wrapper"},[_c('Typography',{staticClass:"ce-howitworks-claim--title",attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h4,"tag":"strong"}},[_vm._v("\n              Votre Offre Découverte\n            ")]),_c('Typography',{staticClass:"ce-howitworks-claim--description",attrs:{"tag":"p"}},[_vm._v("\n              Commandez votre Offre Découverte et faites tester à votre animal\n              pendant 2 semaines\n            ")])],1)]),_c('div',{class:[
            'ce-howitworks--claim',
            { isActive: _vm.isClaimActive(_vm.SHIPPING_ID) } ],on:{"click":function($event){return _vm.selectClaim(_vm.SHIPPING_ID)},"mouseenter":function($event){return _vm.selectClaim(_vm.SHIPPING_ID)}}},[_c('img',{staticClass:"ce-howitworks-claim--image",attrs:{"width":"40","height":"40","src":require("@front/common/assets/number_four.svg"),"alt":"Numéro quatre"}}),_c('div',{staticClass:"ce-howitworks-claim-text--wrapper"},[_c('Typography',{staticClass:"ce-howitworks-claim--title",attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h4,"tag":"strong"}},[_vm._v("\n              Les livraisons flexibles\n            ")]),_c('Typography',{staticClass:"ce-howitworks-claim--description",attrs:{"tag":"p"}},[_vm._v("\n              Si Japhy plaît à votre boule de poils, on poursuit l'aventure\n              avec des livraisons régulières, sans engagement !\n            ")])],1)])]),_c('TwicImg',{staticClass:"ce-howitworks--image",attrs:{"ratio":"none","focus":"auto","alt":"Illustration du processus d'abonnement chez Japhy","src":_vm.selectedImageUrl}})],1),_c('a',{attrs:{"href":/profile-builder/}},[_c('Button',{staticClass:"ce-howitworks--button",attrs:{"id":"homepage-cta-how-it-works"}},[_vm._v("\n        Je découvre\n      ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }