var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ce-products--wrapper"},[_c('div',{staticClass:"ce-products--container"},[_c('Typography',{staticClass:"ce-products--title",attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h2,"tag":"h2"}},[_vm._v("\n      Faites-lui plaisir avec nos produits adaptés à ses besoins\n    ")]),(!_vm.isArrayEmpty(_vm.sortedProducts))?_c('Slider',{staticClass:"ce-products--products",attrs:{"disable-dots":false,"options":{
        perView: 4,
        breakpoints: {
          500: {
            perView: 1,
          },
          1112: {
            perView: 2,
          },
        },
      }}},_vm._l((_vm.sortedProducts),function(product){return _c('Product',{key:product.id,staticClass:"ce-products--product",attrs:{"product":product}})}),1):_vm._e(),_c('a',{attrs:{"href":/profile-builder/}},[_c('Button',{staticClass:"ce-products--button",attrs:{"id":"homepage-cta-products"}},[_vm._v("\n        Je crée son menu\n      ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }