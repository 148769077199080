import { spawn } from 'xstate'

// eslint-disable-next-line default-param-last
export const createSubMachines = (schema = {}, context, subMachines) => {
  const { initialValues = {} } = context

  return Object.entries(schema?.fields || []).map(([key, { spec }]) => {
    const value = context.values[key] || initialValues[key] || ''
    const subMachineContext = { name: key, value, initialValue: value }
    const selectedMachine = subMachines[spec.formField]
    const machine = selectedMachine.withContext({
      ...subMachineContext,
      schema,
    })

    return {
      field: subMachineContext,
      ref: spawn(machine, key),
    }
  })
}

export const makeEveryFieldsTouched = ({ fields, touched }) => {
  const updatedFields = fields
    .filter((field) => !field.touched)
    .reduce(
      (acc, { field }) => ({
        ...acc,
        [field.name]: true,
      }),
      {},
    )

  return {
    ...touched,
    ...updatedFields,
  }
}
