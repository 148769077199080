<template>
  <div :class="$style.wrapper">
    <div v-for="link in links" :key="link.label">
      <Link :link="link.src" :child-class="$style.link">
        <Typography
          v-if="isArrayEmpty(link.links)"
          :variant="TYPOGRAPHY_TYPES.bodyBold"
          :class="$style.label"
        >
          {{ link.label }}
        </Typography>
      </Link>

      <Popper
        v-if="!isArrayEmpty(link.links)"
        :trigger-class="[$style.label, $style.triggerClass]"
      >
        <template #trigger="{ isVisible }">
          <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
            {{ link.label }}
          </Typography>

          <NavArrowIcon :is-visible="isVisible" :class="$style.navArrowIcon" />
        </template>

        <template #content>
          <List>
            <ListItem v-for="subLink in link.links" :key="subLink.label">
              <Link
                :child-class="$style.link"
                :link="subLink.src"
                :class="$style.linkWrapper"
              >
                <ListItemButton :class="$style.label">
                  {{ subLink.label }}
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
        </template>
      </Popper>
    </div>
  </div>
</template>

<script>
import { isArrayEmpty } from '@front/common/utils'
import {
  List,
  ListItem,
  ListItemButton,
  NavArrowIcon,
  Popper,
  Typography,
  TYPOGRAPHY_TYPES,
} from '@front/ui'
import { defineComponent } from '@vue/composition-api'

import Link from '@showcase/components/Link.vue'

export default defineComponent({
  components: {
    List,
    ListItem,
    ListItemButton,
    NavArrowIcon,
    Popper,
    Typography,
    Link,
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return { TYPOGRAPHY_TYPES, isArrayEmpty }
  },
})
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: center;
  gap: $ds-spacing-mobile-extra-small;
}

.triggerClass {
  &:hover {
    border-radius: 8px;
    background: rgba($ds-color-black, 0.1);
  }
}

.link {
  display: inline;
  color: $ds-color-black;
}

.navArrowIcon {
  margin-left: $ds-spacing-mobile-extra-small;
}

.label {
  padding: $ds-spacing-mobile-extra-small;
  text-transform: uppercase;
}

.linkWrapper {
  width: 100%;
}
</style>
