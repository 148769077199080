import { validatePhoneNumberLength } from 'libphonenumber-js'
import * as yup from 'yup'

import { SELECT, INPUT } from './constants'

yup.setLocale({
  mixed: {
    required: 'mixed_required',
    notType: 'mixed_type_error',
  },
  number: {
    positive: 'number_positive',
    integer: 'number_integer',
    min: ({ min }) => ({ key: 'number_min', values: { min } }),
  },
  string: {
    email: 'string_email',
    min: ({ min }) => ({
      key: 'Ce champ doit contenir {min, plural, =1 {# caractère} other {# caractères}} minimum',
      values: { min },
    }),
    max: ({ max }) => ({
      key: 'Ce champ ne peut pas dépasser plus de {max, plural, =1 {# caractère} other {# caractères}}.',
      values: { max },
    }),
  },
})

function dispeoCharacterCheck() {
  return this.test(
    'no-special-character',
    'Vous ne pouvez pas utiliser de caractère spécial.',
    (value) => {
      return !/[^A-Za-z0-9A-zÀ-ÿ\s']|[ç]/.test(value)
    },
  )
}

function addFormFieldMethod(field) {
  const validFields = [INPUT, SELECT]

  if (!validFields.includes(field)) {
    throw new Error(`The form field is not valid. Try: ${validFields}`)
  }

  const next = this.clone()

  next.spec.formField = field
  return next
}

function addFallbackValueMethod() {
  return this.transform((currentValue, originalValue) => {
    return originalValue !== '' ? currentValue : null
  })
}

function addPasswordMethod() {
  const characters = `-^_+!'"#$%&()*,‐./:;<=>?@`
  const charactersRegex = new RegExp(`.*[${characters}].*`, 'gi')

  return this.matches(/.*[a-z].*/, 'password_lower_case')
    .matches(/.*[A-Z].*/, 'password_upper_case')
    .matches(charactersRegex, () => ({
      key: 'password_special_characters',
      values: { characters },
    }))
    .matches(/.*[0-9].*/, 'password_number')
    .min(8)
}

function addPhoneNumberMethod() {
  return this.test({
    name: 'phone-number',
    test(value = '') {
      const { path, createError } = this

      if (!value.length) {
        return true
      }

      switch (validatePhoneNumberLength(value)) {
        // @TODO: Manage errors with locales
        case 'NOT_A_NUMBER': {
          return createError({
            path,
            message: 'Le numéro ne doit pas contenir de lettre',
          })
        }

        case 'INVALID_COUNTRY': {
          return createError({
            path,
            message: 'Le pays ne correspond pas au numéro indiqué',
          })
        }

        case 'TOO_SHORT': {
          return createError({ path, message: 'Le numéro est trop court' })
        }

        case 'INVALID_LENGTH': {
          return createError({ path, message: 'INVALID_LENGTH' })
        }

        case 'TOO_LONG': {
          return createError({ path, message: 'Le numéro est trop long' })
        }

        default: {
          return true
        }
      }
    },
  })
}

function addDOMTOMMethod() {
  return this.test({
    test(value = '') {
      const { path, createError } = this
      const forbiddenPostalCodeRegex =
        /^(971|973|975|972|974|976|984|987|986|988)([0-9])+/

      if (forbiddenPostalCodeRegex.test(value)) {
        return createError({ path, message: 'Nous ne livrons pas aux DOM-TOM' })
      }

      return true
    },
  })
}

yup.addMethod(yup.string, 'formField', addFormFieldMethod)
yup.addMethod(yup.number, 'formField', addFormFieldMethod)
yup.addMethod(yup.object, 'formField', addFormFieldMethod)
yup.addMethod(yup.array, 'formField', addFormFieldMethod)

yup.addMethod(yup.number, 'withFallbackValue', addFallbackValueMethod)
yup.addMethod(yup.string, 'password', addPasswordMethod)
yup.addMethod(yup.number, 'isNotDOMTOM', addDOMTOMMethod)
yup.addMethod(yup.string, 'phone', addPhoneNumberMethod)
yup.addMethod(yup.string, 'dispeoCharacterCheck', dispeoCharacterCheck)

export default yup as any
