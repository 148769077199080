import { defineCustomElement } from 'vue-demi'

import Container from './components/Container.ce.vue'
import FAQ from './components/FAQ.ce.vue'
import Footer from './components/Footer.ce.vue'
import HowItWorks from './components/HowItWorks.ce.vue'
import Ingredients from './components/Ingredients.ce.vue'
import Manufacture from './components/Manufacture.ce.vue'
import MemberArea from './components/MemberArea.ce.vue'
import Press from './components/Press.ce.vue'
import Products from './components/Products'
import Simulator from './components/Simulator'
import Social from './components/Social.ce.vue'
import Testimonials from './components/Testimonials'

export function defineLazyCustomElement() {
  customElements.define('press-custom', defineCustomElement(Press))
  customElements.define('container-custom', defineCustomElement(Container))
  customElements.define('simulator-custom', defineCustomElement(Simulator))
  customElements.define('ingredients-custom', defineCustomElement(Ingredients))
  customElements.define('products-custom', defineCustomElement(Products))
  customElements.define('faq-custom', defineCustomElement(FAQ))
  customElements.define('social-custom', defineCustomElement(Social))
  customElements.define('footer-custom', defineCustomElement(Footer))
  customElements.define('manufacture-custom', defineCustomElement(Manufacture))
  customElements.define('howitworks-custom', defineCustomElement(HowItWorks))
  customElements.define('member-area-custom', defineCustomElement(MemberArea))
  customElements.define(
    'testimonials-custom',
    defineCustomElement(Testimonials),
  )
}

if (typeof window !== 'undefined') {
  window.register = defineLazyCustomElement
}

export {
  Container,
  Press,
  Simulator,
  Ingredients,
  Products,
  FAQ,
  Social,
  Footer,
  Manufacture,
  HowItWorks,
  MemberArea,
  Testimonials,
}
