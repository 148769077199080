export function getPositionFromAngle(originX, originY, radius, angle) {
  const radian = (angle * Math.PI) / 180

  return {
    x: originX + radius * Math.cos(radian),
    y: originY + radius * Math.sin(radian),
  }
}

export function createCurvedLine(fromControlPoint, toControlPoint, point) {
  return `${fromControlPoint.x} ${fromControlPoint.y} ${toControlPoint.x} ${toControlPoint.y} ${point.x} ${point.y}`
}
