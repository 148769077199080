import { Machine, assign, sendParent } from 'xstate'

export default Machine({
  id: 'select',
  initial: 'idle',
  context: {
    initialValue: '',
    name: '',
    value: '',
    touched: false,
  },
  states: {
    idle: {
      on: {
        CHANGE: {
          actions: [
            assign({ touched: true }),
            assign({
              value: (_, event) => event.data,
            }),
            sendParent((context) => ({
              type: 'FIELD.COMMIT',
              field: context,
            })),
            sendParent('VALIDATE_FIELDS'),
          ],
        },
      },
    },
  },
})
